import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import HtmlParser from "react-html-parser";
import Press_bg from "../../images/press/press.png";

function Banner({data}) {

    return (
        <div className="services_banner press_banners" style={{backgroundImage: `url(${Press_bg})`}}>
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6} className="my-auto">
                        <div className="banenr_content">
                            <div className="example" data-text={data?.button_body_txt}>
                                <h1>{HtmlParser(data?.name)}</h1>
                            </div>
                            <div
                                className="banner_content_txt listing_flex">{HtmlParser(data?.content)}</div>
                        </div>
                    </Col>

                </Row>
            </Container>
        </div>
    );
}

export default Banner;