import React, {useEffect, useState} from 'react';
import Banner from "./banner";
import Pressgrid from "./pressgrid";
import Layout from "../layout/layout";
import Loader from "../components/Loader";
import axios from "axios";
import {baseURL} from "../../utils/constant";


function Index(props) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true);
        const response = await axios.get(`${baseURL}/press-page`);
        setUserData(response.data.data);
        setIsLoaded(false);
    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, []);
    return (

        <Layout>
            {isLoaded ?
                <Loader/>
                :
                <>
                    <Banner data={userData}/>
                    <Pressgrid/>
                </>
            }
        </Layout>
    );
}

export default Index;